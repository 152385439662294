import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Switch from 'react-switch';
import swal from 'sweetalert';
import Signup from '../../../authentication/pages/Signup';
import http from '../../../resources/http';
import {
  CLIENTS,
  DELETE_USER,
  GET_CLIENT_USERS,
  UPDATE_USER,
} from '../../../routes/accounts';
import MultipleSelectCheckmarks from '../../../shared/MultiSelect/MultiSelect';
import { roles, rolesStaff } from '../../../data/data';
import { DeleteModal } from '../../../shared/DeleteModal';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_THREAT_CATEGORIES,
  GET_USERS,
} from '../../../graphql/superAdmin/query/query';
import {
  DELETE_USER_ACCOUNT,
  UPDATE_USERS,
} from '../../../graphql/superAdmin/mutation/users/mutation';
import toast from 'react-hot-toast';

const ManageUsers = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [id, setId] = useState();
  const [val, setVal] = useState();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState(0);
  const [userType, setUsertype] = useState();
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const showModal = () => setModal((prevState) => !prevState);
  const [edit, setEditModal] = useState(false);
  const showEditModal = () => setEditModal((prevState) => !prevState);
  const [data, setData] = useState([]);
  const [clients, setClients] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectErrors, setSelectErrors] = useState('Please select user type');
  const [enabled, setEnabled] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const [isCyberMindAdmin, setIsCyberMindAdmin] = useState('');
  let localStorageData = JSON.parse(localStorage.getItem('cyber-minds'));
  const isUserCMSAdmin = localStorageData?.user?.is_cyberminds_admin;
  const usersRole = isUserCMSAdmin ? roles : rolesStaff;

  console.log(usersRole, 'usersRole');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = () =>
    setDeleteConfirmation(!deleteConfirmation);

  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page
  const { data: users, refetch } = useQuery(GET_USERS, {
    variables: { limit: 1000 },
  });
  const pageCount = Math.ceil(users?.user.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };
  const totalItems = data?.exploitable_level_aggregate?.count || 0;

  const [forrm, setForrm] = useState({}); //form
  const [errors, setErrors] = useState({}); //errors
  const setField = (field, value) => {
    setForrm({
      ...forrm,
      [field]: value,
    });

    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const validateForm = () => {
    const {
      userTypeSelect = '',
      companySelect = '',
      userEmail = '',
      userUsername = '',
    } = forrm; //declare the variables and assign the values from the form object
    const newErrors = {};
    // if (!userTypeSelect || userTypeSelect === "")
    //   newErrors.userTypeSelect = "User type is require.";

    if (!validateEmail(userEmail))
      //very simple email format validation
      newErrors.userEmail = 'Please enter a valid email address.';
    if (!userEmail || userEmail === '')
      newErrors.userEmail = 'Email is required.';
    if (!userUsername || userUsername === '')
      newErrors.userUsername = 'Username is required.';
    return newErrors;
  };
  console.log(users, 'users');
  const fetchData = () => {
    http
      .get(`${GET_CLIENT_USERS}`)
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log('No Data To Show');
        }
      )
      .catch((err) => {
        return false;
      });
    http
      .get(`${CLIENTS}`)
      .then((response) => {
        setClients(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log(err);
        }
      );
  };
  // useEffect(() => {
  //   fetchData();
  // }, []);
  const [del] = useMutation(DELETE_USER_ACCOUNT);
  const deletUser = async (e) => {
    try {
      const { data } = await del({
        variables: { id: parseInt(id) },
      });
      if (data?.delete_user?.affected_rows > 0) {
        refetch();
        toggleDeleteModal();
        toast.success('It has been deleted successfully');
      } else {
      }
    } catch (error) {
      toast.error('Failed to delete item:');
    }
  };
  const [updateMutation] = useMutation(UPDATE_USERS);
  const updateUsers = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      try {
        const response = await updateMutation({
          variables: {
            id: parseInt(val),
            input: {
              // status: status,
              email: email,
              username: username,
              // user_type: userList,
              client_pk: parseInt(forrm.companySelect),
            },
          },
        });
        console.log(response, 'response');

        if (response.data.update_user.affected_rows > 0) {
          toast.success('Client updated successfully!');
          refetch();
          showEditModal();
        } else if (response && response.errors && response.errors.length > 0) {
          // Extract the first error message
          const errorMessage =
            response.errors[0].message ||
            'An error occurred while updating the client.';
          toast.error(errorMessage);
        }
      } catch (error) {
        toast.error('An unexpected error occurred. Please try again.');
        console.error(error);
      }
    }
  };

  const [userList, setUserList] = useState([]);
  console.log(userList, 'selectedUserType');
  const isCyberMindAdminSelected = userList.includes('CybermindAdmin');
  console.log(isCyberMindAdminSelected, 'isCyberMindAdminSelected');

  const handleUserTypeOptionsChange = (selected) => {
    setUserList(selected);
  };
  const DisplayData = users?.user
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.filter((items) => items.is_superuser === false)
    ?.map((users) => {
      return (
        <>
          <tr key={users?.id}>
            <td data-label="Username">{users?.username}</td>
            <td className="" data-label="Email">
              {users?.email}
            </td>
            <td data-label="Company">{users?.client?.name}</td>
            <td data-label="Company">
              {users?.roles?.map((role) => role?.role_name).join(',')}
            </td>
            <td
              data-label="Status"
              className="flex space-x-2 justify-center items-center"
            >
              <Switch
                checked={users.is_active === 'true'}
                onChange={(checked) => {
                  const newStatus = checked ? 'active' : 'inactive';
                  setStatus(newStatus);
                  setFetchLoading(true);
                  // http
                  //   .patch(`${UPDATE_USER}${users.id}`, {
                  //     status: newStatus,
                  //   })
                  //   .then(() => {
                  //     fetchData();
                  //     setFetchLoading(false);
                  //   })
                  //   .catch((err) => {
                  //     swal(err);
                  //   });
                }}
              />

              <p></p>
            </td>

            <td>
              <IconButton
                onClick={() => {
                  setVal(users.id);
                  setIsCyberMindAdmin(users.roles?.[0]?.role_name);
                  forrm.userUsername = users.username;
                  forrm.userEmail = users.email;
                  setUsertype(users.user_type);
                  forrm.companySelect = users.client;
                  setStatus(users.status);
                  setSelectedUserType(users.user_type);
                  showEditModal();
                }}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  setId(users.id);
                  toggleDeleteModal();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </td>
          </tr>
        </>
      );
    });
  return (
    <div className="p-10 ">
      {deleteModal ? (
        <DeleteModal handleDelete={deletUser} handleModal={toggleDeleteModal} />
      ) : null}
      <div className="table-title ">
        <span className="font-bold ">Users</span>
        <div className="flex justify-between items-center space-x-4">
          <button className="btn_file_download">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height={28}
              width={28}
              fill-rule="evenodd"
              fill="white"
              clip-rule="evenodd"
              image-rendering="optimizeQuality"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              viewBox="0 0 17639 17639"
            >
              <path
                fill="#1e7145"
                d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
              />
              <path
                fill="#1e7145"
                d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
              />
            </svg>
          </button>
          <div className="btn-add-new">
            <button onClick={() => showModal()}>
              <AddIcon className="" />
            </button>
          </div>
        </div>
      </div>
      <table class="tables">
        <thead className="sticky-header">
          <tr>
            <th scope="col">Username</th>
            <th scope="col">Email</th>
            <th scope="col">Company</th>
            <th scope="col">User Type</th>
            <th scope="col">Status</th>
            <th scope="col">Manage</th>
          </tr>
        </thead>
        <tbody>{DisplayData}</tbody>
      </table>

      <div className="">
        {users?.user?.length > 5 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            activeClassName={'paginationActive'}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={'paginationBttns'}
            previousLinkClassName={'previousBttn'}
            nextLinkClassName={'nextBttn'}
            disabledClassName={'paginationDisabled'}
          />
        )}
      </div>

      {/* adding modal */}
      <Modal show={modal}>
        <Modal.Header
          onClick={() => {
            showModal();
            fetchData();
          }}
        >
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Signup
          refetch={refetch}
          handleModalShowHide={showModal}
          fetchData={fetchData}
        />
      </Modal>
      {/* editing modal */}
      {edit ? (
        <Modal show={true}>
          <Modal.Header onClick={() => showEditModal()}>
            <Modal.Title>Update User</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-medium">
            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Username
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                value={forrm.userUsername}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setField('userUsername', e.target.value);
                }}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Username"
                required
                isInvalid={!!errors.userUsername}
                name="email"
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.userUsername}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Email
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                value={forrm.userEmail}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setField('userEmail', e.target.value);
                }}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="john.doe@company.com"
                isInvalid={!!errors.userEmail}
                required
                name="email"
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.userEmail}
              </Form.Control.Feedback>
            </Form.Group>
            {isCyberMindAdmin !== 'CybermindAdmin' ? (
              <Form.Group class="mb-6">
                <Form.Label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Company Name
                </Form.Label>
                <Form.Control
                  as="select"
                  id="email"
                  value={forrm.companySelect}
                  onChange={(e) => {
                    setCompany(e.target.value);
                    setField('companySelect', e.target.value);
                  }}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Company Name"
                  required
                  isInvalid={!!errors.companySelect}
                  name="email"
                >
                  <option value="" selected="selected">
                    {forrm.companySelect?.length > 0
                      ? forrm.companySelect
                      : 'Select Company'}
                  </option>
                  {users?.client?.map((x, y) => (
                    <option value={x.id}>{x.name}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.companySelect}
                </Form.Control.Feedback>
              </Form.Group>
            ) : null}
            <Form.Group class="">
              <Form.Label
                for="countries"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Select User Type
              </Form.Label>

              {/* <MultiSelect
                options={options}
                value={selectedUserType}
                onChange={setSelectedUserType}
              /> */}
              <MultipleSelectCheckmarks
                data={usersRole}
                onChange={handleUserTypeOptionsChange}
                tag="Select User Type"
                initialSelectedValues={selectedUserType}
              />
              {enabled && selectedUserType?.length < 1 ? (
                <p className="text-red-600">Please select user type</p>
              ) : null}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => showEditModal()} className="btn-cancel">
              Cancel
            </Button>
            <Button
              className="btn-add-new"
              onClick={(e) => {
                updateUsers(e);
                setEnabled(true);
              }}
            >
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
};

export default ManageUsers;
