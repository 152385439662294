import { gql } from '@apollo/client';
export const GET_BUSINESS_PROCESS = gql`
  query GET_BUSINESS_PROCESS($limit: Int!, $offset: Int) {
    business_process(limit: $limit, offset: $offset) {
      id
      name
    }
  }
`;

export const GET_EXECUTIVE_DASHBOARD_ANALYSIS = gql`
  query GET_EXECUTIVE_DASHBOARD_ANALYSIS($business_process_id: ID!) {
    executive_dashboard_analysis(business_process_id: $business_process_id) {
      loss_exposure
      total_investment
      revenue_contribution
      top_five_threats {
        name
      }
    }
  }
`;
export const GET_BUSINESS_IMPACT_ANALYSIS = gql`
  query GET_BUSINESS_IMPACT_ANALYSIS($business_process_id: ID!) {
    business_impact_analysis(business_process_id: $business_process_id) {
      revenue_contribution
      key_business_impact
      estimated_business_losses
      criticality
      regulatory_requirements
      overall_risk_rating
    }
  }
`;
export const GET_THREAT_PROFILING = gql`
  query GET_THREAT_PROFILING($business_process_id: ID!) {
    threat_profiling_analysis(business_process_id: $business_process_id) {
      frequency_of_occurrence
      incident_response_distribution{
        lost_business_cost
        detection_and_escalation
        ex_post_response
        notification
      }
      breach_response_distribution{
        lost_business_cost
        detection_and_escalation
        ex_post_response
        notification
      }
      incident_cost
      breach_cost
    }
  }
`;
export const GET_CONTROL_ANALYSIS = gql`
  query GET_CONTROL_ANALYSIS($limit: Int!, $offset: Int) {
    control_analysis(limit: $limit, offset: $offset) {
      id
      client {
        id
        name
      }
      business_process {
        name
      }
      control {
        name
        control_type {
          label
        }
        control_domain {
          label
        }
        control_category {
          label
        }
        primary_threat{
          name
        }
        secondary_threats{
          name
        }
      }
      is_applicable
      is_implemented
    }
  }
`;

export const UPDATE_CONTROL_ANALYSIS_MUTATION = gql`
  mutation UpdateControlAnalysis($id: Int!, $isApplicable: Boolean, $isImplemented: Boolean) {
    update_control_analysis(
      where: { id: { exact: $id } }
      input: { is_applicable: $isApplicable, is_implemented: $isImplemented }
    ) {
      affected_rows
    }
  }
`;

export const GET_EXPLOITABLE_PATH_QUERY = gql`
  query GetExploitablePath {
    exploitable_path(
      where: {
        entry: { name: { isnull: false } }
        middle: { name: { isnull: false } }
        end: { name: { isnull: false } }
      }
    ) {
      entry {
        name
      }
      middle {
        name
      }
      end {
        name
      }
    }
  }
`;

export const GET_INVESTMENT_ANALYSIS = gql`
  query GetInvestmentAnalysis {
    investment_analysis(limit: 100) {
      id
      business_process {
        id
        name
      }
      control {
        name
        control_type {
          label
        }
        control_domain {
          label
        }
        control_category {
          label
        }
      }
      investment
      reduction_percentage
    }
  }
`;

export const GET_INVESTMENT_ANALYSIS_RESULT = gql`
  query InvestmentAnalysisResult($businessProcessId: ID!, $appliedControls: [ID!]!) {
    investment_analysis_result(
      business_process_id: $businessProcessId
      applied_controls: $appliedControls
    ) {
      actual {
        scope
        residual_risk_value
        probability_of_occurrence
        average_lose_exposure
      }
      new {
        scope
        residual_risk_value
        probability_of_occurrence
        average_lose_exposure
      }
      total_cost_saving
      investment_requirement
      return_on_investment
    }
  }
`;
export const SAVE_INVESTMENT_SCENARIO_DATA = gql`
  mutation SaveInvestmentScenario($inputs: [investment_senarioInputType]!) {
    create_investment_senario(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const GET_INVESTMENT_SCENARIOS = gql`
  query GetInvestmentScenarios($limit: Int!) {
    investment_senario(limit: $limit) {
      id
      senario_name
      total_cost_saving
      investment_requirement
      return_on_investment
      actual
      new
    }
  }
`;