import React, { useEffect, useState } from 'react';
import CustomTable from '../../../shared/CustomTable';
import http from '../../../resources/http';
import {
  CONTROL_EFFECTIVENESS_MATRIXES,
  CONTROL_EFFECTIVENESS_MATRIX_BY_PK,
} from '../../../api/excelUpload';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import SearchHandler from '../../../shared/SearchHandler';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import AddIcon from '@mui/icons-material/Add';
import swal from 'sweetalert';
import ModalHandler from './ModalHandler';
import { downloadExcelData } from '../../../utils/ExportExcelUtility';
import ModalFileUpload from '../../../shared/ModalFileUpload';
import { GET_CONTROL_EFFECTIVENESS } from '../../../graphql/superAdmin/query/query';
import { useQuery } from '@apollo/client';

const ControlEffectivenessMatrix = () => {
  const url_create = CONTROL_EFFECTIVENESS_MATRIXES;
  const url_get = CONTROL_EFFECTIVENESS_MATRIXES;
  const url_by_pk = CONTROL_EFFECTIVENESS_MATRIX_BY_PK;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);
  const [id, setId] = useState();
  const [datas, setData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  // for file upload
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
  // search
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = [
    'control_type',
    'control_domain',
    'control_category',
  ];
  const debounceDelay = 400;

  //data
  async function fetchData() {
    setFetchLoading(true);
    try {
      const response = await http.get(url_get);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setFetchLoading(false);
    }
  }
  // useEffect(() => {
  // 	fetchData();
  // }, []);
  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { data, loading, error, refetch } = useQuery(
    GET_CONTROL_EFFECTIVENESS,
    {
      variables: { limit: pageSize, offset: page * pageSize },
    }
  );

  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };

  const totalItems = data?.control_effectiveness?.count || 0;
  console.log(JSON.stringify(data), 'data');

  const filteredData = SearchHandler(
    data?.control_effectiveness??[],
    searchValue,
    fieldsToFilterBy
  );

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      await http.delete(`${url_by_pk}${id}`);
      fetchData();
      setFetchLoading(false);
      swal('Success', 'It has been deleted successfully', 'success');
    } catch (error) {
      swal('Failed', 'Failed to delete item', 'error');
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };

  return (
    <main>
      <div className="main-container">
        <div className="table-title ">
          <span>Control Effectiveness Matrix</span>
          <div className="flex justify-between items-center space-x-4">
            <SearchInput
              searchValue={searchValue}
              setSearchValue={handleInputChange}
            />
            {/* for upload */}
            {/* <button
							onClick={() => {
								setIsModalUploadOpen(true);
							}}
							className="btn_file_download"
						>
							<img
								alt="upload-avatar"
								className="w-7 h-7"
								src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
							/>
						</button> */}
            <button
              className="btn-add-new"
              onClick={() => {
                setSelectedItem(null);
                setMode('add');
                setIsModalOpen(true);
              }}
            >
              <AddIcon />
            </button>
          </div>
        </div>
        {/* table */}
        {fetchLoading ? (
          <LoadingComponent />
        ) : searchValue.length > 0 && filteredData.length === 0 ? (
          <div className="alert-no-data">
            No data available for
            <span className="search-value">{searchValue}</span>
            at the moment.
          </div>
        ) : (
          <CustomTable
            filteredData={filteredData}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
            page={page}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            passedExcludeColumns={['__typename']}
          />
        )}
        {/* add and edit modal */}
        <ModalHandler
          data={data}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mode={mode}
          size="xl"
          selectedItemId={id}
          selectedItem={selectedItem}
          header="Control Effectiveness Matrix"
          url_create={url_create}
          url_by_pk={url_by_pk}
          fetchData={refetch}
        />

        {/* for uploading new file */}

        <ModalFileUpload
          // UploadURL={UploadURL}
          fetchData={fetchData}
          isOpen={isModalUploadOpen}
          onClose={() => setIsModalUploadOpen(false)}
        />
      </div>
    </main>
  );
};

export default ControlEffectivenessMatrix;
