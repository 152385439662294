import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import cronusBlueNew from '../../assets/imgs/cronusBlueNew.png';
import apiClient from '../../resources/apiClient';
import {
  name,
  pid,
  set_token,
  set_user_data,
  set_user_status,
} from '../store/actions';
import { baseURL } from './../../resources/apiClient';
import http from './../../resources/http';
import { Input } from './InputBox';
import swal from 'sweetalert';
import { storeCurrentDateTimeInLocalStorage } from '../../utils/helpers';
import LoadingComponent from './../../shared/LoadingDropDownSelection/LoadingComponent';
import { useMutation } from '@apollo/client';
import { LOGIN } from './../../graphql/shared/mutation/mutation';

const MultiFactorAuthentication = (props) => {
  const [reset, setReset] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const inputRefs = useRef([]);

  const [otp, setOtp] = useState(Array(6).fill(''));
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  let localStorageUserData = JSON.parse(localStorage.getItem('data'));
  const fetchFirstBusinessProcessToShowUpInBIAPage = (favorite) => {
    dispatch(pid(favorite.id));
    dispatch(name(favorite.name));
  };
  const [login, { data, loading: mutationLoading }] = useMutation(LOGIN);
  const submit = async () => {
    try {
      setLoading(true);
      const response = await login({
        variables: {
          email: localStorageUserData.email,
          password: localStorageUserData.password,
        },
      });
      setLoading(false);
      const userData = response.data.login;
      storeCurrentDateTimeInLocalStorage();
      // localStorage.setItem(
      //   'data',
      //   JSON.stringify({
      //     localStorageUserData.email,
      //     localStorageUserData.password,
      //     userId: userData.user.id,
      //   })
      // );
      localStorage.setItem('totp', userData.user.totp);
      // dispatch(setUserName(userData.user.username));
      if (userData.user.is_active) {
        localStorage.removeItem('data');
        console.log(userData, 'response');
        storeCurrentDateTimeInLocalStorage();
        dispatch(set_token(userData.token));
        dispatch(
          set_user_data({
            ...userData.user,
            totp: userData.user.totp,
          })
        );
        dispatch(
          set_user_status({
            access_token: userData.token,
            loggedIn: true,
            refresh_token: userData.refresh_token,
          })
        );

        // if (userData.user.favorite_business_process !== null) {
        //   fetchFirstBusinessProcessToShowUpInBIAPage(
        //     userData.user.favorite_business_process
        //   );
        // }
        if (userData.user.is_superuser) {
          history.push('/super_threat_vectors');
        } else {
          history.push('/');
        }
      } else {
        // setStatus('inactiveUser');
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      // setStatus('invalidUser');
    }
  };
  const submits = async () => {
    try {
      await apiClient.auth
        .login({
          username: localStorageUserData?.email,
          password: localStorageUserData?.password,
        })
        .then(
          (response) => {
            localStorage.removeItem('data');
            console.log(response.data, 'response');
            storeCurrentDateTimeInLocalStorage();
            dispatch(set_token(response.data.access_token));
            dispatch(
              set_user_data({
                ...response.data.user,
                totp: response.data.user.totp,
              })
            );
            dispatch(
              set_user_status({
                approved: response.data.user.approved,
                loggedIn: true,
                access_token: response.data.access_token,
                refresh_token: response.data.refresh_token,
              })
            );

            if (response.data.user.favorite_business_process !== null) {
              fetchFirstBusinessProcessToShowUpInBIAPage(
                response.data.user.favorite_business_process
              );
            }
            if (
              response.data.user.is_client_user &&
              response.data.user.is_client_admin
            ) {
              history.push('/');
            } else if (response.data.user.is_client_user) {
              history.push('/');
            } else if (response.data.user.is_cyberminds_admin) {
              history.push('/super_threat_vectors');
            } else if (response.data.user.is_client_admin) {
              history.push('/');
            } else if (response.data.user.user_type[0] === 'CMCSupport') {
              history.push('/manage_tickets');
            } else {
              return null;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } catch (err) {
      console.log(err);
    }
  };
  const handleChangeReset = () => {
    http
      .post(`${baseURL}/account/send-recovery-code`, {
        email: localStorageUserData?.email,
      })
      .then((response) => {
        swal(
          'Success',
          `A recovery code has been sent to ${localStorageUserData?.email}.`,
          'success'
        );
        setReset(true);
      })
      .catch((err) => {
        console.log(err);
        swal('Error', 'Invalid or expired code', 'error');
      });
  };
  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
  };

  const handleKeyDown = (e, index) => {
    if (e.target.value.length === 1) {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (e.keyCode === 8 && e.target.value === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  // console.log(inputRefs.current.length, "teste");
  const validateOtp = () => {
    setLoading(true);

    http
      .post(`${baseURL}/account/verify-otp`, {
        user_id: localStorageUserData?.userId,
        otp: Number(otp.join('')),
      })
      .then((response) => {
        setLoading(false);
        setError(false);
        submit();
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setErrorMessage(
          'Invalid, the code has either expired or has been entered incorrectly.'
        );
      });
  };
  useEffect(() => {
    // Check if all six digits are entered
    if (otp.every((digit) => digit !== '')) {
      // validateOtp(); // Call validateOtp function
      submit(); // Call validateOtp function
    }
  }, [otp]);
  const validateRecoveryCode = (e) => {
    e.preventDefault();
    http
      .post(`${baseURL}/account/verify-recovery-code`, {
        email: localStorageUserData.email,
        recovery_code: recoveryCode,
      })
      .then((response) => {
        localStorage.setItem('totp', response.data.totp);
        history.push('/multifactor-authentication-setup');
        // submit(e);
      })
      .catch((err) => {
        console.log(err);
        swal('Error', 'Invalid or expired code', 'error');
      });
  };
  useEffect(() => {
    setError(false);
  }, [otp]);
  return (
    <div className="flex flex-col h-screen justify-center items-center text-center">
      {reset ? (
        <div className="w-1/3  flex flex-col items-center space-y-4">
          <span class="bg-yellow-100 text-yellow-800 text-xl font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">
            Please enter the recovery code that has been sent to your email{' '}
            {localStorageUserData?.email} to regain account access.
          </span>

          <input
            type="text"
            id="first_name"
            value={recoveryCode}
            onChange={(e) => setRecoveryCode(e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter recovery code here"
            required
          />
          <button
            type="button"
            style={{ backgroundColor: '#ce9f2c' }}
            class="text-white w-44 bg focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md w-full sm:w-auto px-5 py-3 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={(e) => {
              validateRecoveryCode(e);
            }}
          >
            Submit
          </button>
        </div>
      ) : (
        <div className="flex flex-col h-screen justify-center items-center text-center">
          <div>
            <img
              className="h-44 w-44 rounded-full"
              src={cronusBlueNew}
              alt="Cronus logo"
            />
          </div>
          <div className="mb-6">
            <label
              for=" Multi-factor"
              className="text-2xl mb-8 font-medium text-gray-900 dark:text-gray-300"
            >
              Multi-factor Authentication
            </label>
            {error && (
              <p className="text-red-200 text-lg mt-2 w-96 text-center">
                {errorMessage}
              </p>
            )}

            <div className="flex flex-row ms-16 justify-between items-center rounded-2xl">
              {Array.from({ length: 6 }, (_, i) => (
                <Input
                  className="rounded-2xl"
                  key={i}
                  index={i}
                  otp={otp}
                  handleChange={handleChange}
                  handleKeyDown={handleKeyDown}
                  inputRef={(input) => (inputRefs.current[i] = input)}
                />
              ))}
            </div>

            <div className="">
              <p className="text-m mt-2  text-center dark:text-gray-300">
                A verification code has been generated by the Authenticator app,
              </p>{' '}
              <p className="text-m  text-center dark:text-gray-300">
                you'll need to enter the code to continue.
              </p>{' '}
              <span class="bg-indigo-100 text-indigo-800  font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
                Lost your Authenticator app? click on Reset code button below
              </span>
            </div>
          </div>
          <div className="mb-10 flex flex-row justify-space-center items-center space-x-4">
            {inputRefs.current.length > 0 ? (
              <div className="flex space-x-4">
                <button
                  style={{ backgroundColor: '#6c757d' }}
                  type="button"
                  onClick={() => {
                    setOtp(Array(6).fill(''));
                  }}
                  class="text-white bg focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md w-full sm:w-auto px-5 py-3 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Clear
                </button>
              </div>
            ) : null}
            <button
              type="button"
              style={{ backgroundColor: '#ce9f2c' }}
              class="text-white bg focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md w-full sm:w-auto px-5 py-3 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => {
                handleChangeReset();
              }}
            >
              Reset code
            </button>
          </div>
        </div>
      )}
      {loading ? <LoadingComponent /> : null}
    </div>
  );
};
export default withRouter(
  connect(null, { set_token, set_user_status, set_user_data })(
    MultiFactorAuthentication
  )
);
