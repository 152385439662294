import React, { useEffect, useState } from "react";
import CardSelector from "../Analysis/CardSelector";
import StatsCard from "../../shared/Component/StatsCard";
import TopCyberThreats from "../../Charts/ExecutiveDashboard/TopCyberThreats";
import costOfIncident from "../TopFiveThreats";
import BusinessObjectives from "../../Charts/ExecutiveDashboard/BusinessObjectives";
import DiscreteSliderMarks from "../../components/ExecutiveDashboard/Sliders/Slider";
import DiscreteSliderMarksInveStment from "../../components/ExecutiveDashboard/Sliders/InvestmentSlider";
import { useApi } from "../../hooks/useApis";
import { baseURL } from "../../resources/apiClient";
import LoadingSpinner from "../../utils/LoadingSpinner";
import TopThreats from "../TopFiveThreats";
import { GET_EXECUTIVE_DASHBOARD_ANALYSIS } from "../../graphql/client/query";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";

const ExecutiveDashboard = () => {
  // const { data, fetchData, isLoading } = useApi(
  //   `${baseURL}/business_process/executive-dashboard-details`
  // );
  // useEffect(() => {
  //   fetchData();
  // }, []);
  // console.log(data, "executive details");


  const processId = useSelector((state) => state.pid);

  const { data, loading, error, refetch } = useQuery(GET_EXECUTIVE_DASHBOARD_ANALYSIS, {
    variables: { business_process_id: parseInt(processId)??0},
  });

  return (
    <div>
        <div className="p-10 space-y-4 md:space-y-6">
          <div className=" z-10 grid place-items-center sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3  gap-4">
            <StatsCard
              className="flex-1"
              title={"Loss Exposure"}
              value={parseInt(data?.executive_dashboard_analysis?.loss_exposure)}
              change={"14"}
              isPositive={true}
              useIndicator={true}
            />
            <StatsCard
              title={"Total Investment"}
              value={parseInt(data?.executive_dashboard_analysis?.total_investment)}
              change={"34"}
              isPositive={true}
              useIndicator={false}
            />
            <StatsCard
              title={"Revenue Contribution"}
              value={parseInt(data?.executive_dashboard_analysis?.revenue_contribution)}
              change={"4"}
              isPositive={false}
              useIndicator={false}
            />
          </div>

          <div className=" z-10 grid place-items-center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2  gap-4">
            <CardSelector />
            <div className="bg-white  w-full h-full  dark:bg-gray-800 shadow rounded-lg p-4 flex flex-col items-center justify-between">
              <div className=" items-center justify-center text-xl font-medium text-gray-500 dark:text-gray-400">
                Top Five Threats
              </div>
              <div className="h-full">
                <TopThreats />
              </div>
            </div>
          </div>

          <div className=" z-10 grid place-items-center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2  gap-4">
            <div className="bg-white  w-full h-full  dark:bg-gray-800 shadow rounded-lg p-4 flex flex-col items-center justify-between">
              <div className="text-xl font-medium text-gray-500 dark:text-gray-400">
                Top Cyber Risks (%)
              </div>
              <TopCyberThreats />
            </div>

            <div className="bg-white  w-full h-full  dark:bg-gray-800 shadow rounded-lg p-4 flex flex-col items-center justify-between">
              <div className="text-xl font-medium text-gray-500 dark:text-gray-400">
                Contribution to Business Objectives (%)
              </div>
              <BusinessObjectives />
            </div>
          </div>

          <div className=" z-10 grid place-items-center sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3  gap-4">
            <div className="bg-white  w-full h-full  dark:bg-gray-800 shadow rounded-lg p-4 flex flex-col items-center justify-between">
              <div className="flex flex-col h-full">
                <div className="text-xl font-medium text-gray-500 dark:text-gray-400">
                  Risk Reduction (%)
                </div>
                <DiscreteSliderMarks />
              </div>
            </div>
            <div className="bg-white  w-full h-full  dark:bg-gray-800 shadow rounded-lg p-4 flex flex-col items-center justify-between">
              <div className="flex flex-col h-full">
                <div className="text-xl font-medium text-gray-500 dark:text-gray-400">
                  Business Requirement
                </div>
                <DiscreteSliderMarksInveStment />
              </div>
            </div>
            <div className="bg-white  w-full h-full  dark:bg-gray-800 shadow rounded-lg p-4 flex flex-col items-center justify-between">
              <div className="flex flex-col h-full">
                <div className="text-xl font-medium text-gray-500 dark:text-gray-400">
                  Investment Requirement
                </div>
                <DiscreteSliderMarksInveStment />
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ExecutiveDashboard;