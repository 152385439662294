import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import './index.css';
import authStore from './authentication/store';
import { ThemeProvider } from './context/ThemeContext';

// Create the Apollo Client
const client = new ApolloClient({
  uri: 'https://api-new.cronusgrx.io/graphql', // replace with your GraphQL endpoint
  cache: new InMemoryCache(),
});

// Create the React Query Client
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={authStore}>
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </Provider>,
  document.querySelector('#root')
);
