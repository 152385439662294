import React, { useState, useEffect } from "react";
import StatsCard from "../../shared/Component/StatsCard";
import { baseURL } from "../../resources/apiClient";
import { useApi } from "../../hooks/useApis";
import http from "../../resources/http";
import AppLicationHigherClassificationLevel from "../../Charts/ExecutiveDashboard/AppLicationHigherClassificationLevel";
import ComplianceRequirements from "../../Charts/ExecutiveDashboard/ComplianceRequirements";
import RiskProfileDashboardDonut from "../../Charts/ExecutiveDashboard/RiskProfileDashboardDonut";

const card = [
	{
		id: "card1",
		title: "Risk Profile Summary",
	},
	{
		id: "card2",
		title: "Highest Data Classifications Level",
	},
	{
		id: "card3",
		title: "Compliance Requirements",
	},
];
const CardSelector = () => {
	const [selectedCard, setSelectedCard] = useState(card[0]);
	const [topThreats, setTopThreats] = useState([]);
	const data=[]
	// const { data } = useApi(
	// 	`${baseURL}/business_process/get-my-business-process`
	// );
	const fetchTopThreats = async () => {
		const topThreaResponse = await http.get(
			`${baseURL}/business_process/business-process-summery`
		);
		setTopThreats(topThreaResponse.data);
	};
	// useEffect(() => {
	// 	fetchTopThreats();
	// }, []);

	const renderContent = (card) => {
		if (card.id === "card1") {
			return <RiskProfileDashboardDonut data={data} />;
		} else if (card.id === "card2") {
			return <AppLicationHigherClassificationLevel data={topThreats} />;
		} else if (card.id === "card3") {
			return <ComplianceRequirements data={topThreats} />;
		}
		return <div>No specific content available at the moment</div>;
	};

	return (
		<div className="bg-white w-full h-full dark:bg-gray-800 shadow rounded-lg p-4 flex flex-col items-center justify-between">
			<div className="items-center justify-center text-xl font-medium text-gray-500 dark:text-gray-400">
				{`Application ${selectedCard.title}`}
			</div>
			<div className="p-10 flex h-full flex-col md:flex-row">
				<div className="flex-grow md:w-full">
					<div
						id={`content-${selectedCard.id}`}
						role="tabpanel"
						aria-labelledby={selectedCard.id}
						className="transition-opacity duration-500 h-full"
					>
						{renderContent(selectedCard)}
					</div>
				</div>
				<div className="flex-grow md:w-full md:flex md:flex-col md:items-start md:gap-2">
					{card.map((card, index) => (
						<button
							key={card.id}
							aria-controls={`content-${card.id}`}
							aria-selected={selectedCard.id === card.id}
							role="tab"
							className={`flex w-full p-2 text-left rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none ${
								selectedCard.id === card.id
									? "bg-gray-300 dark:bg-gray-600"
									: ""
							}`}
							onClick={() => setSelectedCard(card)}
						>
							{card.title}
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

export default CardSelector;
