import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation LOGIN($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      refresh_token
      token
      user {
        is_superuser
        id
        created_at
        updated_at
        email
        username
        is_active
        last_login
        roles {
          id
          role_name
        }
        client {
          id
        }
      }
    }
  }
`;
